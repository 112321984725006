<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="6">
                    <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="form.module_name" :disabled="disabled"
                                :state="errors[0] ? false : null"></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="module_code" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('module')">
                            <b-form-input v-model="form.module_code" :disabled="disabled"
                                :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <!-- <b-col cols="12" md="6">
                    <ValidationProvider name="module_code" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('module')">
                            <program-module-selectbox v-model="form.module_code" :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col> -->
                <!-- <b-col cols="12" md="6">
                    <ValidationProvider name="faculty" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="faculty" :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col> -->
                <b-col cols="12" md="6">
                    <ValidationProvider name="program_code" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="form.program_code" :disabled="disabled" :faculty_code="faculty"
                                :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="status" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('status')">
                            <b-form-select v-model="form.status" :disabled="disabled"
                                :options="[{ text: $t('active'), value: 'a' }, { text: $t('passive'), value: 'p' }]"
                                :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <div class="d-flex justify-content-end mt-2 px-2">
                        <b-button :variant="status == 'delete' ? 'danger' : 'primary'" @click="save" :disabled="loading">
                            {{ buttonText }}
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
//Service
import ProgramModuleService from "@/services/ProgramModuleService";

export default {
    name: "ProgramModuleDataForm",
    props: {
        formId: {
            type: Number,
            default: -1
        },
        status: {
            type: String,
            default: ''
        },
    },
    watch: {
        formId: {
            handler(val) {
                if (val && val > -1) {
                    this.get(val)
                } else if (val == -1) {
                    this.form = {}
                }
            },
            immediate: true
        }
    },
    computed: {
        disabled() {
            return ['delete', 'show'].includes(this.status)
        },
        buttonText() {
            switch (this.status) {
                case 'new':
                    return this.$t('add')
                case 'show':
                    return this.$t('close')
                case 'update':
                    return this.$t('update')
                case 'delete':
                    return this.$t('delete')
                default:
                    return ''
            }
        }
    },
    data() {
        return {
            loading: false,
            faculty: null,
            form: {}
        }
    },
    async created() {
    },
    methods: {
        async save() {
            if (this.status == 'show') {
                this.$emit('close')
                return
            }

            const valid = await this.$refs['formModalValidate'].validate()
            if (!valid) {
                return
            }
            let response = null
            switch (this.status) {
                case 'new':
                    response = await ProgramModuleService.store(this.form).catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    break;
                case 'update':
                    response = await ProgramModuleService.store(this.form).catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    break;
                case 'delete':
                    response = await ProgramModuleService.remove(this.form.id).catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    break;

                default:
                    break;
            }
            if (response.data.success) {
                this.$toast.success(this.$t('api.' + response.data.message));
                await this.$refs['formModalValidate'].reset()
                if (['update', 'delete','new'].includes(this.status)) {
                    this.$emit('close')
                } else if (this.status == 'new') {
                    this.form = {}
                }
            }
        },
        get(id) {
            ProgramModuleService.get(id)
                .then((response) => {
                    this.form = response.data.data;
                })
                .catch((error) => {
                    this.showErrors(error)
                });
        }
    }
}
</script>
