<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('program_modules')" @new-button-click="showForm(null, 'new')" :isNewButton="true"
                    @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('program_modules')" @new-button-click="showForm(null, 'new')" :isNewButton="true"
                    @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.module_name">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('module_code')">
                            <b-form-input v-model="datatable.queryParams.filter.module_code">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('program_code')">
                            <b-form-input v-model="datatable.queryParams.filter.program_code">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('program')">
                            <program-selectbox :faculty_code="datatable.queryParams.filter.faculty_code"
                                :department_code="datatable.queryParams.filter.department_code"
                                v-model="datatable.queryParams.filter.program_code"></program-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label="$t('module_status')">
                            <b-form-select v-model="datatable.queryParams.filter.status"
                                :options="[{ text: $t('active'), value: 'a' }, { text: this.$t('passive'), value: 'p' }]">
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable">
            </datatable>
            <CommonModal ref="showModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
                <template v-slot:CommonModalTitle>
                    {{ $t('module') }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formId="formId" :status="formStatus" @close="formClose" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
//Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
//Component
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
//Other
import qs from 'qs'
//Service
import ProgramModuleService from "@/services/ProgramModuleService";
//Page
import ShowForm from "./ShowForm"
export default {
    name: "ProgramModulePage",
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        ShowForm
    },
    metaInfo() {
        return {
            title: this.$t('program_module')
        }
    },
    data() {
        return {
            formId: -1,
            formStatus: null,
            form: {},
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                callback: (row) => {
                                    this.showForm(row.id, 'show');
                                },
                            },
                            {
                                text: this.$t('edit'),
                                class: 'ri-edit-box-line',
                                callback: (row) => {
                                    this.showForm(row.id, 'update');
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-2-line',
                                callback: (row) => {
                                    this.showForm(row.id, 'delete');
                                }
                            },
                        ]
                    },
                    {
                        label: this.$t('module_code'),
                        field: 'module_code',
                        sortable: false,
                    },
                    {
                        label: this.$t('module_name'),
                        field: 'module_name',
                        sortable: false,
                    },
                    {
                        label: this.$t('program_code'),
                        field: 'program_code',
                        sortable: true,
                    },
                    {
                        label: this.$t('program_name'),
                        field: 'program_name',
                        sortable: false,
                    },
                    {
                        label: this.$t('module_status'),
                        field: 'status',
                        sortable: false,
                        formatFn: (value, row) => {
                            return this.$t(row.status == 'a' ? 'active' : 'passive');
                        },
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return ProgramModuleService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        async formClose() {
            this.$refs.showModal.$refs.commonModal.hide()
            if (this.formStatus !== 'show') {
                this.getRows()
            }
            this.formClear()
        },
        formClear() {
            if (this.formStatus == 'new') {
                this.getRows()
            }
            this.formId = -1
            this.formStatus = null
        },
        showForm(id, status) {
            this.formId = id
            this.formStatus = status
            this.$refs.showModal.$refs.commonModal.show()
        },
    },
    created() {
        this.getRows()
    }
};
</script>

